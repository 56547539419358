@import '../../../styles/common/common.scss';

$slide-item-max-width: 320px !default;
$slide-item-margin-right: 25px !default;

.root {
    margin-top: var(--component-unhook-height);
    margin-bottom: var(--component-unhook-height);
    max-width: 100vw;
    overflow: hidden;
}

.mg_slide_container {
    /*min-height: rem(390px);*/
    width: 100%;
    color: var(--utmb-color-default);
    text-align: left;
    display: flex;
    justify-content: center;
    padding: 2.1875rem 2rem;
    z-index: 99 !important;
    overflow: hidden !important;

    :global .embla__container {
        @include bp(max-width md) {
            width: 100% !important;
        }
    }

    .mg_slide_item {
        position: relative;
        background-color: var(--utmb-color-white);
        box-shadow: none;
        margin-right: rem(30px);
        overflow: visible;
        
        flex: 0 0 80vw;

        @include bp(md) {
            flex: 0 0 rem($slide-item-max-width);
        }

        &:last-child {
            margin-right: rem(100px);
            @include bp(md) {
                margin-right: 0;
            }
        }

        @include bp(md) {
            margin-right: rem(30px);
        }

        @include bp(lg) {
            box-sizing: border-box !important;
        }

        &:hover,
        &:focus-within {
            box-shadow: 0 0 rem(35px) rgba(0, 0, 0, 0.17);
        }

        .slider_photo_item {
            position: relative;
            height: rem(150px);
        }

        .slider_content_item {
            position: relative;
            padding: rem(25px);
        }

        &_offset {
            margin-top: rem(80px);

            @include bp(md) {
                margin-top: rem(80px);
            }
        }
    }

    .card_content p {
        font-size: rem(14px);
    }
}

/////////////////////////
// Card Bottom Variant //
/////////////////////////

.slide_picture_bottom_container {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--utmb-color-default);
    text-align: left;
    padding: rem(24px);
    z-index: 99;
    overflow: visible;

    // @include bp(xl) {
    //     padding: rem(24px) calc((100% - rem(1010px)) / 2);
    // }

    :global .embla__container {
        @include bp(max-width md) {
            width: 100% !important;
        }
    }

    .card_picture_bottom_container {
        position: relative;
        flex: 0 0 100%;
        background-color: var(--utmb-color-white);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:not(:last-child) {
            margin-right: rem($slide-item-margin-right);
        }

        @include bp(lg) {
            flex: 0 0 rem($slide-item-max-width);
        }

        @include hover-focus {
            box-shadow: 0 0 rem(35px) rgba(0, 0, 0, 0.15);
        }

        .slider_photo_item {
            position: relative;
            height: rem(340px);
        }

        .slider_content_item {
            position: relative;
            padding: rem(25px);

            @include bp(lg) {
                padding: rem(50px) rem(25px) rem(25px);
            }

            .content_title {
                text-align: center;
                font-size: rem(22px);
                line-height: rem(30px);
                margin-bottom: rem(25px);
            }

            .content_summary > * {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-align: center;
                font-size: rem(16px);
                line-height: rem(22px);
                margin-bottom: rem(25px);
            }

            .content_link {
                text-align: center;
                margin-bottom: rem(10px);
            }
        }
    }
}

.card_bordered {
    border: rem(1px) solid var(--utmb-color-white-light-hover);
}

//////////
// Dots //
//////////

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    @extend .mob_pad;
}

.hide_all_bp {
    opacity: 0;
    @include bp(max md) {
        opacity: 1 !important;
    }
}

.hide_dots_md {
    @include bp(md) {
        opacity: 0;
    }
}

.hide_dots_xl {
    @include bp(xl) {
        opacity: 0;
    }
}

:export {
    slideItemMaxWidth: $slide-item-max-width;
    slideItemMarginRight: $slide-item-margin-right;
}
